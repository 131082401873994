<template>
  <div class="wrapper ">
     <div class="wrapper__container">
        <jublia-header />
    <div class="container container-transparent">
     
      <div class="container-ins">
          <jublia-h1 class="DINBold text-jublia-bluest mb-6 uppercase">
          {{ $t("settings-remaind.title") }}
        </jublia-h1>

        <jublia-p class="text" v-html="$t('settings-remaind.text')" />
        <div class="mt-4 w-full">
          <div class="relative flex flex-col  w-full mt-2">
            <div style="margin: auto; margin-bottom: 0.2rem; width: 74%"
            class="relative flex flex-col items-center justify-center w-full mt-2"
            >
              <router-link to='/sms-settings' style="height: 185px;">
                <div
                  style="
                    margin-bottom: 3.5rem;
                    text-align: center;
                    height: auto;
                    padding: 11px;
                    cursor: pointer;
                  "
                  class="
                    w-full
                    item
                    h-12
                    rounded-lg
                    mt-2
                    px-4
                    text-xl text-jublia-blue
                    flex flex-col items-center justify-center
                  "
                >
                 <img
                src="@/assets/icons/SMS.png"
                alt="next button"
                width="130"
                height="18"
              />
                 
                </div>
              </router-link>
              
              <router-link to='/email-settings' style="height: 185px;margin: 1rem;">
                <div
                  style="
                    margin-bottom: 3.5rem;
                    text-align: center;
                    height: auto;
                    padding: 11px;
                    cursor: pointer;
                  "
                  class="
                    w-full
                    item
                    h-12
                    rounded-lg
                    mt-2
                    px-4
                    text-xl text-jublia-bluest
                    flex flex-col items-center justify-center
                  "
                >
                  <img
                src="@/assets/icons/calande.png"
                alt="next button"
                width="130"
                height="18"
              />
              <jublia-p style="font-size:1.8rem;font-weight: 700" 
               class="mt-4" > {{ $t("settings-remaind.calander") }}</jublia-p>
                </div>
              </router-link>
            </div>
            <jublia-button
          :width="true"
           @click="go(`/menu`)"
            class="relative m-1 mx-auto  font"
           style="text-transform: uppercase; font-size: 1.8rem;"
          >
        <div  style="line-height: 30px !important; " class="mt-2 mb-1">
         {{ $t('user-settings.back') }}
         </div>
          </jublia-button>
          </div>
          <!--<router-link to="/menu">
            <jublia-button


             class="relative mt-4 border-jublia-blue"
             
           >
            Facebook
           </jublia-button>
         </router-link>
          <jublia-button
          v-on:click="loginGoogle()"
              color="red"
             class="relative mt-4 border-jublia-blue"
             type="submit"
           >
            Google
           </jublia-button>
          -->
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JubliaP from "@/components/text/jublia-p";
import JubliaButton from "@/components/button/jublia-button";
import JubliaH1 from "@/components/text/jublia-h1";
import JubliaH3 from "@/components/text/jublia-h3";

import JubliaHeader from "@/components/header/jublia-header";

export default {
  name: "login",

  components: {
    //JubliaButton,
    "jublia-button": JubliaButton,
    "jublia-header": JubliaHeader,
   "jublia-h3": JubliaH3,
    "jublia-h1": JubliaH1,
    "jublia-p": JubliaP,
  },
 methods: {
    go(){
      let a=`/menu`
     
     this.$router.push(a)
    }
 }
}
</script>
<style scoped>
.container-ins {
  max-width: 870px;
  margin: 0 auto;
  
}
.item:hover{
transform: scale(1.05);
 transition:  .5s ease;
}
.container-content {
  @apply w-full;
  @apply m-auto;
  @apply pb-8;
  @apply px-8;
  max-width: 900px;

  @media all and (min-width: 767px) {
    max-width: 500px;
  }
}
</style>
